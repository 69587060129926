.app-button {
  padding: 0 32px;
  height: 55px;
  span {
    font-family: Fahkwang, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
  }

}
