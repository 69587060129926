.shop-showing-results {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

.category-list {
  padding: 0 0 0 14px;
  li {
    margin-top: 20px;
    list-style-type: circle;

   &::marker {
     color: #a5682c;
   }
  }
}

.category-list-item {
  color: #2C4C59;
  font-family: Fahkwang, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-transform: capitalize;
}

.filter-title {
  color: #223840;
  font-family: Fahkwang, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
