.product-detail {
  color: #2C4C59;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.product-main-img {
  height: 650px;
  width: 100%;
}
.product-small-img {
  height: 163px;
}

.product-detail-title {
  color: #223840;
  font-family: Fahkwang, sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
}

.product-detail-subtitle {
  color: #4E5B60;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.product-detail-old-price {
  color: #839297;
  text-align: center;
  font-family: Fahkwang, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-decoration-line: line-through;
}

.product-detail-price {
  color: #223840;
  font-family: Fahkwang, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.size-label {
  color: #4E5B60;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

.btn {
  padding: 14px;
  font-size: 15px;
  font-family: Fahkwang, sans-serif;
  cursor: pointer;
  width: 52px;
  height: 55px;
  border: 1px solid #C0C6C6;
  background-color: transparent;
  color: #2C4C59;
}

.add-remove-btn {
  border: 1px solid #C0C6C6;
  background-color: transparent;
  color: #2C4C59;
  &:active {
    border: 1px solid #2C4C59;
    background-color: #2C4C59;;
    color: white;
  }
}
