.contact-us-image {
  height: 400px;
  max-width: 500px;
}

.contact-us-title {
  color: #223840;
  font-family: Fahkwang, sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
}

.contact-us-subtitle {
  color: #4E5B60;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 420px;
}

.contact-us-form-container {
  max-width: 650px;
  font-family: Montserrat, sans-serif;
}
