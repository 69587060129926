.product-card {
  font-family: 'Fahkwang', sans-serif;
  width: 304px;
}

.product-card-image {
  height: 320px;
}

.product-card-title {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
}

.product-card-price {
  text-align: center;
  font-family: Fahkwang, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.product-card-old-price {
  color: #839297;
  text-align: center;
  font-family: Fahkwang, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-decoration-line: line-through;
}

.sale-icon {
  height: 60px;
  width: 60px;
  background-color: #2C4C59;
  color: #FFFF;
  right: 12px;
  top: 12px;

  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

@media screen and (max-width:1160px) {
  .product-card {
    width: 250px;
  }

  .sale-icon {
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-width:1024px) {
  .product-card {
    width: 230px;
  }

  .product-card-image {
    height: 220px;
  }
}
