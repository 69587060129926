body {
    background: #FFFCF9;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

.text-title-1 {
    color: #223840;
    font-family: Fahkwang, sans-serif;
    font-size: 68px;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
}

.text-title-2 {
    color: #223840;
    text-align: center;
    font-family: Fahkwang, sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 600;
    line-height: 62px;
}

.text-title-3 {
    color: #223840;
    text-align: center;
    font-family: Fahkwang, sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
}

.text-title-4 {
    color: #223840;
    text-align: center;
    font-family: Fahkwang, sans-serif;
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
    line-height: 62px;
}

.text-content-1  {
    color: #4E5B60;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.text-content-2  {
    color: #4E5B60;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
