.statistic-title {
  color: #223840;
  text-align: center;
  font-family: Fahkwang, sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.category-text {
  color: #4E5B60;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

.middle-item-stats {
  border-right: 1px solid #c0c6c6;
  border-left: 1px solid #c0c6c6;
}
