.arrow-btn {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 1px solid #C0C6C6;
  background-color: transparent;
}

.arrow-btn:active {
  border: 1px solid #2C4C59;
  background-color: #2C4C59;
  path {
    fill: white !important;
  }
}
