
.btn-group {
  padding: 14px;
  font-size: 15px;
  font-family: Fahkwang, sans-serif;
  cursor: pointer;
  width: 52px;
  height: 55px;
}

.outlined-btn {
  border: 1px solid #C0C6C6;
  background-color: transparent;
  color: #2C4C59;
}

.filled-btn {
  border: 1px solid #2C4C59;
  background-color: #2C4C59;;
  color: white;
}
