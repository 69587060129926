.input {
    height: 38px;
    outline: 0;
    border-right: 0;
    border-left: 0;
    border-top: 0;
    border-bottom: 1px solid #C0C6C6;

    color: #4E5B60;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: Montserrat, sans-serif;
}

textarea:focus, input:focus {
    color: #4E5B60;
}
