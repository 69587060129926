.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    display: flex;
    justify-content: center;
    height: 50px;
    text-align: center;
    margin: auto 4px;
    color: #2C4C59;
    box-sizing: border-box;
    align-items: center;
    border-radius: 50px;
    line-height: 19px;
    font-size: 15px;
    font-family: Fahkwang, sans-serif;
    min-width: 50px;
    border: solid #C0C6C6 1px;
    background-color: transparent;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: #2C4C59;
      color: #FFF;
      border: none
    }

    .arrow {
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(225deg);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &:disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
