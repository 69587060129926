.checkbox {
    outline: 0;
    border-color: #C0C6C6;
}

.checkbox-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

input:focus {
    color: #4E5B60;
}
