.hero-main-title-container {
  width: calc(100% + 40px);
  z-index: 1;
  margin-top: 52px;
}

.main-price-text {
  font-family: Fahkwang, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}



