.horizontal-line {
  border-bottom: 1px solid #E8D7CC;
  height: 1px;
}

.separator-categories {
  font-size: 14px;
}

.categories-img {
  width: 303px;
  height: 303px;
  background-color: #C0C6C6;
}

.categories-shaped-img {
  border-radius: 0 0 151.5px 151.5px;
}

.category-option {
  color: #2C4C59;
  text-align: center;
  font-family: Fahkwang, sans-serif;
  font-size: 42px;
  font-weight: 400;
  line-height: 50px;
  margin-bottom: 30px;
}
