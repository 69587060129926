.dropdown {
  font-family: Montserrat, sans-serif;
  position: relative;
  z-index: 99;
  font-size: 14px;
  color: #4E5B60;
  background-color: transparent;
}

.custom-dropdown-container {
  position: relative;
  width: 260px;
  border: 1px solid #C0C6C6;
  border-radius: 28px;
  overflow: hidden;
  height: 55px;
}

.custom-dropdown-header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
}

.custom-dropdown-container.open {
  border-bottom: 1px solid #fefcf9;
  border-radius: 28px 28px 0 0;
}

.custom-dropdown-options {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #C0C6C6;
  border-top: none;
  border-radius: 0 0 28px 28px;
  background-color: #fefcf9;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.custom-dropdown-options li {
  padding: 8px 16px;
  cursor: pointer;
}
