.output-value {
  border: 1px solid #E8D7CC ;
  padding: 14px 24px;
  border-radius: 23px;
  color: #223840;
  font-family: Fahkwang, sans-serif;
  font-size: 15px;
}

.output-container {
  margin: 20px 0;
  min-width: 250px;
}
