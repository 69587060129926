.error-page-content {
  max-width: 370px;
}

.round-error-svg {
  fill: #E8D7CC;
  max-width: 700px;
  width: 66%;
}

.round-error-svg-2 {
  fill: rgba(232, 215, 204, 0.47);
  max-width: 1240px;
  width: 80%;
}
