.about-square-img {
  height: 560px;
  width: 530px;
  background-color: #C0C6C6;
}

.about-rounded-img {
  height: 256px;
  width: 256px;
  background-color: #FFFCF9;
  position: absolute;
  z-index: 1;
  top: 230px;
  left: -140px;
}

.about-title {
  font-family: Fahkwang, sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
}

.about-content-text {
  margin: 25px 0 35px;
  max-width: 516px;
}

.about-rounded-img-container {
  position: relative;
}
