.header-container {
    height: 46px;
}

.logo-text {
    font-family: 'Fahkwang', sans-serif ;
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 1.6px;
}

.nav-elements {
    display: flex;
    list-style-type: none;
}

.nav-elements a {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #4E5B60;
    text-decoration: none;
}

.nav-elements  a.active {
    position: relative;
}

.nav-elements  a.active::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #2C4C59;
}

